import React from "react"
import { ChevronUpIcon } from "@heroicons/react/24/outline"
import { useTranslation } from "react-i18next"

const ButtonToForm = () => {
  const handleClickScroll = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }

  const { t } = useTranslation()

  return (
    <button
      className="rounded-full py-[0.625rem] px-5 flex flex-row font-poppins text-base lg:text-xl font-medium leading-[130%] bg-gradient-to-br from-gradient-light-purple to-purple-primary items-center"
      onClick={handleClickScroll}
    >
      <p className="mr-2">{t("talkToOneOfOurSpecialists")}</p>
      <ChevronUpIcon strokeWidth={2} className="h-6 w-6" />
    </button>
  )
}

export default ButtonToForm
