import React from "react"
import GradientText from "../landing-page/gradient-text"
import OtcPerksCard from "./otc-perks-card"
import OtcChartIcon from "../../images/otc-chart-icon.svg"
import OtcSwapIcon from "../../images/otc-swap-icon.svg"
import OtcSupportIcon from "../../images/otc-support-icon.svg"
import ButtonToForm from "./button-to-form"
import { useTranslation } from "react-i18next"

const SectionOtcPerks = () => {
  const { t } = useTranslation()
  return (
    <section className="grid grid-cols-12 md:gap-x-6 xs:gap-y-5 md:gap-y-0 w-full mb-28">
      <div className="col-span-12 w-full flex flex-col mb-12 font-poppins text-center font-medium leading-[110%]">
        <h6 className="text-green-neon text-xl lg:text-2xl">
          {t("howOtcTradingCanUnleash")}
        </h6>

        <div className="h-4" />

        <div className="text-3xl lg:text-[2.5rem] mt-4">
          <GradientText>{t("itsSoSimple")}</GradientText>
        </div>
      </div>

      {/* Perks Cards */}
      <OtcPerksCard icon={OtcChartIcon} text={t("ourClientsAcrossTheGlobe")} />
      <OtcPerksCard icon={OtcSwapIcon} text={t("withExclusiveNetworkRates")} />
      <OtcPerksCard
        icon={OtcSupportIcon}
        text={t("receiveSupportFromSpecialists")}
      />

      <div className="col-span-12 mx-auto mt-12">
        <ButtonToForm />
      </div>
    </section>
  )
}

export default SectionOtcPerks
