import React from "react"
import GradientText from "../landing-page/gradient-text"

type CardProps = {
  step: number
  title: string
  text: string
}

const OtcStepCard = ({ step, title, text }: CardProps) => {
  return (
    <div className="xs:col-span-12 md:!col-span-6 flex flex-row items-center border border-gray-900 rounded-3xl font-poppins py-8 px-5 md:py-12 md:px-[1.88rem]">
      <GradientText className="text-[5rem] lg:text-[6.5rem] font-light mr-8 md:mr-12">
        {step}
      </GradientText>
      <div className="flex flex-col">
        <div>
          <GradientText className="text-2xl lg:text-[2rem] font-medium hyphens-auto">
            {title}
          </GradientText>
        </div>

        <div className="h-4" />

        <p className="text-base lg:text-lg font-inter font-normal hyphens-auto">
          {text}
        </p>
      </div>
    </div>
  )
}

export default OtcStepCard
