import React from "react"
import GradientText from "../landing-page/gradient-text"
import { useTranslation } from "react-i18next"

const SectionInfoForm = () => {
  const { t } = useTranslation()
  return (
    <section className="grid grid-cols-12 mb-24" id="form-section">
      <div className="col-span-12 md:col-span-5 w-full flex flex-col">
        <div className="text-center">
          <GradientText className="font-poppins font-medium text-[28px] lg:text-5xl leading-[3.3rem]">
            Private & OTC Desk
          </GradientText>
        </div>

        <div className="flex flex-col mt-8 font-inter font-normal text-base lg:text-lg leading-[150%] gap-y-6">
          <p>{t("weSpecializeInServing")}</p>
          <p>{t("ourFocusIsOnProviding")}</p>
          <p>{t("withOurOtcDesk")}</p>
        </div>
      </div>

      <div className="col-span-12 mt-5 md:mt-0 md:col-start-7 md:col-span-6 flex text-base lg:text-lg flex-col md:text-end md:justify-end">
        <p>
          {t("sendEmailTo")}&nbsp;
          <a className="underline font-semibold" href="mailto:otc@digitra.com">
            otc@digitra.com
          </a>
          &nbsp;{t("withTheContactOfYourChoice")}
        </p>
      </div>
    </section>
  )
}

export default SectionInfoForm
