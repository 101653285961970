import React from "react"
import GradientText from "../landing-page/gradient-text"
import OtcStepCard from "./otc-step-card"
import ButtonToForm from "./button-to-form"
import { useTranslation } from "react-i18next"

const SectionOtcSteps = () => {
  const { t } = useTranslation()
  return (
    <section className="grid grid-cols-12 md:gap-x-10 gap-y-12 mb-14">
      <div className="col-span-12 text-center">
        <GradientText className="text-3xl lg:text-[2.5rem] font-medium font-poppins text-center">
          {t("stepByStep")}
        </GradientText>
      </div>

      <OtcStepCard
        step={1}
        title={t("reachOutToUsNow")}
        text={t("fillInTheFormToStart")}
      />
      <OtcStepCard
        step={2}
        title={t("insideTheAction")}
        text={t("usingTheChannelOfYourChoice")}
      />
      <OtcStepCard
        step={3}
        title={t("theFinishLine")}
        text={t("OurSkilledTrader")}
      />
      <OtcStepCard
        step={4}
        title={t("transparencyCompliance")}
        text={t("wellTransferTheDesiredAsset")}
      />

      <div className="col-span-12 mx-auto ">
        <ButtonToForm />
      </div>
    </section>
  )
}

export default SectionOtcSteps
