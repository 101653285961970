import React, { HTMLProps } from "react"
import GradientText from "../landing-page/gradient-text"

type CardProps = {
  icon: string
  text: string
  className?: HTMLProps<HTMLElement>["className"] | null
}

const OtcPerksCard = ({ icon, text, className }: CardProps) => {
  return (
    <div
      className={`flex flex-col col-span-12 md:col-span-4 p-12 rounded-3xl bg-transparent items-center backdrop-blur border border-gray-900 ${className}`}
    >
      <img src={icon} className="h-40 w-40 mb-12" aria-label={text} />

      <div className="text-center">
        <GradientText className="text-xl lg:text-2xl font-poppins font-medium leading-[150%]">
          {text}
        </GradientText>
      </div>
    </div>
  )
}

export default OtcPerksCard
