import React, { useEffect } from "react"
import LPLayout from "../components/landing-page/lp-layout"
import SectionInfoForm from "../components/otc-page/section-info-form"
import { SEO } from "../components/seo"
import SectionOtcPerks from "../components/otc-page/section-otc-perks"
import SectionOtcSteps from "../components/otc-page/section-otc-steps"

const OtcPage = () => {
  useEffect(() => {
    if (typeof window !== undefined && typeof document !== undefined) {
      const noscript = document.createElement('noscript');

      noscript.innerHTML = `
        <img src="https://ib.adnxs.com/pixie?pi=7b378119-73d3-4c7a-a8dc-79d1256b713b&e=PageView&script=0" width="1" height="1" style="display:none"/>
      `;

      document.body.insertBefore(noscript, document.body.firstChild);
      
      // Cria o elemento de script
      const script = document.createElement('script');

      // Define o conteúdo do script
      script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-P23GV22X63');

        !function(e,i){if(!e.pixie){var n=e.pixie=function(e,i,a){n.actionQueue.push({action:e,actionValue:i,params:a})};n.actionQueue=[];var a=i.createElement("script");a.async=!0,a.src="//acdn.adnxs.com/dmp/up/pixie.js";var t=i.getElementsByTagName("head")[0];t.insertBefore(a,t.firstChild)}}(window,document);
        pixie('init', '7b378119-73d3-4c7a-a8dc-79d1256b713b');

        pixie('event', 'PageView');
      `;

      // Insere o script no início do body
      document.body.insertBefore(script, document.body.firstChild);

      // Limpeza ao desmontar o componente
      return () => {
        document.body.removeChild(script);
        document.body.removeChild(noscript);
      };
    }
  }, []);  // O array vazio significa que este useEffect só é executado uma vez após o primeiro render
  
  return (
    <LPLayout>
      <div className="absolute w-full h-[25rem] xl:h-[30rem] bg-otc-chart bg-cover bg-no-repeat z-0">
        <div className="w-full h-full  bg-gradient-to-b from-transparent to-black to-[150%] xl:to-[120%]" />
      </div>

      <main
        id="main-tag"
        className="flex flex-col items-center max-w-7xl w-full m-auto xs:px-5 xl:px-0 z-10 xs:mt-36 md:mt-44"
      >
        <SectionInfoForm />
        <SectionOtcPerks />
        <SectionOtcSteps />
      </main>
    </LPLayout>
  )
}

export const Head = () => <SEO title="OTC" pathname="otc" pageType="CollectionPage" />

export default OtcPage
